<script>
import { LOGOUT } from '@/store/actions.type'
import { mapState } from 'vuex'
import axios from 'axios'
import OnboardingCard from '../OnboardingCard.vue'
import OnboardingHeading from '../OnboardingHeading.vue'

export default {
  name: 'ContinueLater',

  data() {
    return {
      isContinueLaterRequested: false,
      loading: false,
      previousPath: '',
    }
  },

  components: {
    OnboardingCard,
    OnboardingHeading
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm._data.previousPath = from.path
    })
  },

  computed: {
    ...mapState('auth', ['user'])
  },

  methods: {
    async continueLaterHandler() {
      this.loading = true
      const response = await axios.post('user/continue-install-email-send', { user_id: this.user.id })
      if(response.data.success) {
        this.isContinueLaterRequested = true
        await this.$store.dispatch(`auth/${LOGOUT}`)
      }
      this.loading = false
    }
  }
}
</script>

<template>
  <section class="onboarding v2">

    <template v-if="!isContinueLaterRequested">
      <OnboardingHeading heading="Save account and sign out?" />
      <OnboardingCard>
        <v-btn
          @click="$router.go(-1)"
          block
          class="onboarding-btn"
          color="primary"
          elevation="0">
          Continue with Syncio install
        </v-btn>

        <v-btn
          :loading="loading"
          @click="continueLaterHandler"
          block
          class="register-btn m-t-4"
          elevation="0">
          Sign out and continue later
        </v-btn>
      </OnboardingCard>
    </template>

    <template v-else>
      <OnboardingCard>
        <p class="onboarding-text m-y-0">Account saved, we have sent an email containing instructions to continue to:</p>
        <p class="onboarding-text m-b-0">{{ user.email }}</p>
        <p class="onboarding-text m-b-0">See you again soon!</p>
      </OnboardingCard>

      <aside class="d-flex justify-center">
        <router-link to="/login" class="register-btn">
          Already have an account? Login here
        </router-link>
      </aside>
    </template>

  </section>
</template>